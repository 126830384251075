import React from 'react'
import PropTypes from 'prop-types'
import SEO from '../components/SEO'

const NotFound = ({ pageContext: { locale, breadcrumbs }, location }) => {
  const schema = {
    '@context': 'https://schema.org',
    '@graph': [],
  }
  const ogp = []
  const meta = {
    title: 'Page not found | Turist in Transilvania',
    description: 'This page was not found',
    keywords: '',
    author: 'Turist in Transilvania',
  }

  return (
    <>
      <SEO
        schema={JSON.stringify(schema)}
        ogp={ogp}
        meta={meta}
        locale={locale}
        breadcrumbs={breadcrumbs}
        location={location}
        alternateLanguages={[]}
      />

      <div className='container margin_120'>
        <div className='row'></div>
        <h1>Page Not Found</h1>
        <p>Oops, we couldn't find this page!</p>
      </div>
    </>
  )
}
export default NotFound

NotFound.propTypes = {
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
    breadcrumbs: PropTypes.array.isRequired,
  }).isRequired,
  location: PropTypes.object.isRequired,
}
